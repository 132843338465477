<template>
    <section>

        <b-row>
            <b-col lg="3">
                <div class="fixedElement">
                    <app-collapse accordion>
                        <app-collapse-item title="1. Registration">
                            <ul>
                                <li @click="goto('Registration')"
                                    :class="{ 'active': selected === 'Registration' }">
                                    <feather-icon icon="VideoIcon" /> Registration
                                </li>
                            </ul>
                        </app-collapse-item>
                        <app-collapse-item title="2. Projects">
                            <ul>
                                <li @click="goto('OverviewProjects')"
                                    :class="{ 'active': selected === 'OverviewProjects' }">
                                    <feather-icon icon="VideoIcon" /> Overview and Archiving Projects
                                </li>
                                <li @click="goto('LeadGenerationFunnels')"
                                    :class="{ 'active': selected === 'LeadGenerationFunnels' }">
                                    <feather-icon icon="VideoIcon" /> Creating Lead Generation Funnels
                                </li>
                                <li @click="goto('SalesFunnels')"
                                    :class="{ 'active': selected === 'SalesFunnels' }">
                                    <feather-icon icon="VideoIcon" /> Creating Sales Funnels
                                </li>
                                <li @click="goto('AddProduct')"
                                    :class="{ 'active': selected === 'AddProduct' }">
                                    <feather-icon icon="VideoIcon" /> Add Product
                                </li>
                                <li @click="goto('EmailAutomation')"
                                    :class="{ 'active': selected === 'EmailAutomation' }">
                                    <feather-icon icon="VideoIcon" /> Email Automation
                                </li>
                                <li @click="goto('AutoResponder')"
                                    :class="{ 'active': selected === 'AutoResponder' }">
                                    <feather-icon icon="VideoIcon" /> Auto Responder
                                </li>
                                <li @click="goto('EditingFunnels')"
                                    :class="{ 'active': selected === 'EditingFunnels' }">
                                    <feather-icon icon="VideoIcon" /> Editing Funnels
                                </li>
                                <li @click="goto('UpdatingProjectSettings')"
                                    :class="{ 'active': selected === 'UpdatingProjectSettings' }">
                                    <feather-icon icon="VideoIcon" /> Updating Project Settings
                                </li>
                                <li @click="goto('ProjectDetailPage')"
                                    :class="{ 'active': selected === 'ProjectDetailPage' }">
                                    <feather-icon icon="VideoIcon" /> Project Detail Page
                                </li>
                            </ul>
                        </app-collapse-item>
                        <app-collapse-item title="3. Contacts">
                            <ul>
                                <li @click="goto('Contacts')"
                                    :class="{ 'active': selected === 'Contacts' }">
                                    <feather-icon icon="VideoIcon" /> Contacts
                                </li>
                            </ul>
                        </app-collapse-item>
                        <app-collapse-item title="4. Broadcasting">
                            <ul>
                                <li @click="goto('Broadcasting')"
                                    :class="{ 'active': selected === 'Broadcasting' }">
                                    <feather-icon icon="VideoIcon" /> Broadcasting
                                </li>
                            </ul>
                        </app-collapse-item>
                        <app-collapse-item title="5. Configure SMTP ">
                            <ul>
                                <li @click="goto('ConfigureSMTP')"
                                    :class="{ 'active': selected === 'ConfigureSMTP' }">
                                    <feather-icon icon="VideoIcon" /> Configure SMTP
                                </li>
                            </ul>
                        </app-collapse-item>
                        <app-collapse-item title="6. Payments">
                            <ul>
                                <li @click="goto('Paypal')"
                                    :class="{ 'active': selected === 'Paypal' }">
                                    <feather-icon icon="VideoIcon" /> Paypal
                                </li>
                                <li @click="goto('Stripe')"
                                    :class="{ 'active': selected === 'Stripe' }">
                                    <feather-icon icon="VideoIcon" /> Stripe
                                </li>
                                <li @click="goto('AuthorizeNet')"
                                    :class="{ 'active': selected === 'AuthorizeNet' }">
                                    <feather-icon icon="VideoIcon" /> Authorize.net
                                </li>
                            </ul>
                        </app-collapse-item>
                        <app-collapse-item title="7. Orders">
                            <ul>
                                <li @click="goto('Orders')"
                                    :class="{ 'active': selected === 'Orders' }">
                                    <feather-icon icon="VideoIcon" /> Orders
                                </li>
                            </ul>
                        </app-collapse-item>
                    </app-collapse>



                    <!--<b-button @click="goto('webformCode')"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              block
                              variant="outline-primary">
                        Webform Code
                    </b-button>-->

                </div>
            </b-col>
            <b-col lg="9">

                <b-row>
                    <b-col lg="12" ref="Registration">
                        <b-card class="shadow-none text-center">

                            <b-card-header class="text-center">
                                <h1 class="mb-0">
                                    1. Registration
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe src="https://player.vimeo.com/video/872680105" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="OverviewProjects">
                        <b-card class="shadow-none text-center">

                            <b-card-header>
                                <h1 class="mb-0">
                                    2. Overview and Archiving Projects
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872679509" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="LeadGenerationFunnels">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    3. Creating Lead Generation Funnels
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872702647" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="SalesFunnels">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    4. Creating Sales Funnels
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872700618" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="AddProduct">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    5. Add Product
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872680162" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="EmailAutomation">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    6. Email Automation
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872679799" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="AutoResponder">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    7. Auto Responder
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872702767" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="EditingFunnels">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    8. Editing Funnels
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872680333" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="UpdatingProjectSettings">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    9. Updating Project Settings
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872679641" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="ProjectDetailPage">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    10. Project Detail Page
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872702933" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="Contacts">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    11. Contacts
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872702544" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="Broadcasting">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    12. Broadcasting
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872702866" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="ConfigureSMTP">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    13. Configure SMTP
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872702819" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="Paypal">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    14. Paypal
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872679568" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="Stripe">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    15. Stripe
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872702469" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="AuthorizeNet">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    16. Authorize.net
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872679749" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>

                    <b-col lg="12" ref="Orders">
                        <b-card class="shadow-none">

                            <b-card-header>
                                <h1 class="mb-0">
                                    17. Orders
                                </h1>
                            </b-card-header>
                            <b-card-body class="text-center">
                                <iframe class="trainingVideo" src="https://player.vimeo.com/video/872702738" height="700" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                            </b-card-body>
                        </b-card>
                    </b-col>



                </b-row>
            </b-col>
        </b-row>
    </section>
</template>

<script>
    import BCardCode from '@core/components/b-card-code'

    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'


    import {
        BCard, BCardHeader, BCardBody, BCardImg, BRow, BCol, BCardText,
        BButton,
    } from 'bootstrap-vue'
    import Ripple from "vue-ripple-directive";
    import { $themeColors } from '@themeConfig'

    export default {
        components: {
            BCardCode, BCard, BCardHeader, BCardBody, BCardImg, BRow, BCol, BCardText,
            BButton,
            AppCollapse,
            AppCollapseItem,
        },

        metaInfo: {
            title: 'Landingly | Training'
        },

        data() {
            return {
                loading: false,
                selected: null,
                projects: null,
                error: null
            }
        },

        directives: {
            Ripple,
        },


        watch: {

        },


        methods: {

            goto(refName) {
                this.selected = refName;
                var element = this.$refs[refName];
                var top = element.offsetTop;
                window.scrollTo(0, top);
            }
        },
        created() {

            this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true);
        },

        destroyed() {
            this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false);
        },
    }
</script>

<style>
    .fixedElement {
        position: fixed;
        z-index: 100;
        max-height: 750px;
        overflow-y: auto;
        padding-right: 10px;
        width: 20%;
    }

        .fixedElement .card {
            margin-bottom: 10px;
        }

        .fixedElement .collapse-title {
            font-weight: 800;
            font-size: 17px;
        }

        .fixedElement .card .card-body {
            background-color: #131c34;
            padding: 0;
        }

        .fixedElement ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

            .fixedElement ul li {
                padding: 10px 15px;
                cursor: pointer;
                border-bottom: solid 1px #283046;
            }

                .fixedElement ul li:last-child {
                    border-bottom: 0;
                }

                .fixedElement ul li:hover {
                    background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
                    box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
                }

                .fixedElement ul li.active {
                    background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
                    box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
                }

                .fixedElement ul li svg {
                    width: 25px;
                    height: 20px;
                    margin-right: 10px;
                }

    @media (max-width: 1560px) {
        iframe {
            height: 500px;
        }
    }
</style>